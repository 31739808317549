@import "~@sas-te/alfabeto-tokens";

@import "~App/utils/toast/scss/main";

body {
  background-color: $color-ice;
}

.srOnly {
  @include visually-hidden();
}
