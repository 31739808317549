@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";

.snackbar {
  display: flex;
  padding: $size-xs $size-s;
  font-size: $font-size-s;
  line-height: 1.43;
  letter-spacing: .01071em;
  border-radius: $size-xs;
}

.icon {
  margin-right: $size-xs;
}

.text {
  padding: 2px 0;
}

.isPrimary {
  color: $color-primary-dark;
  background-color: $color-primary-lightest;
}

.isSuccess {
  color: $color-success-darkest;
  background-color: $color-success-lightest;
}

.isWarning {
  color: $color-warning-darker;
  background-color: $color-warning-lightest;
}

.isDanger {
  color: $color-danger-darkest;
  background-color: $color-danger-lightest;
}
