@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";

.user {
  display: inline-flex;
  align-items: center;
  padding: $size-xxs;
  cursor: pointer;
  border-radius: $border-radius-s;
  transition: background-color .2s linear;

  &:hover {
    background-color: $color-ice;
  }
}

.avatar {
  margin-right: $size-s;
}

.contentContainer {
  max-width: 140px;
  line-height: 1;
}

.profileName {
  display: inline-block;
  width: 100%;
  margin-bottom: $size-xxs;
  overflow: hidden;
  font-size: $font-size-xxs;
  font-weight: $font-weight-bold;
  line-height: 1;
  color: $color-primary;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
}

.userName,
.schoolName {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  font-size: $font-size-xs;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.error {
  align-items: center;
}

.dropdown {
  z-index: $zindex-dropdown;
  width: 320px;
  max-width: 100%;
  overflow: hidden;
  text-align: left;
  border-bottom-right-radius: $size-xs;
  border-bottom-left-radius: $size-xs;

  &:global(.sas-box) {
    padding: 0;
    transition: none;
  }
}

.details {
  padding: $size-m;
  line-height: 1;
  border-bottom: 1px solid $color-ink-lightest;

  .profileName {
    margin-bottom: $size-xs;
    font-size: $font-size-xs;
    line-height: 1;
    white-space: normal;
  }

  .userName,
  .schoolName {
    font-size: $font-size-s;
    line-height: 1;
    white-space: normal;
  }
}
