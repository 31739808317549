@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";

.spinner {
  transform-origin: center;
  animation: rotate 2s linear infinite;
  @include transition($speed-fast);

  circle {
    animation: dash 1.5s ease-in-out infinite;
    fill: none;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    cx: 50;
    cy: 50;
    r: 20;
  }
}

// --------------------------------------------------------------
// Sizes
// --------------------------------------------------------------

.--large {
  width: $spinner-size-l;
  height: $spinner-size-l;
}

.--medium {
  width: $spinner-size-m;
  height: $spinner-size-m;
}

.--small {
  width: $spinner-size-s;
  height: $spinner-size-s;
  stroke-width: 4;
}

// --------------------------------------------------------------
// Animation
// --------------------------------------------------------------

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}
