.#{$rt-namespace}__toast {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: $rt-toast-min-height;
  max-height: $rt-toast-max-height;
  padding: $size-s;
  margin-bottom: 1rem;
  overflow: hidden;
  font-size: $font-size-heading-5-small;
  font-weight: $font-weight-medium;
  line-height: 1.25;
  direction: ltr;
  cursor: pointer;
  border-radius: 1px;
  border-radius: $border-radius-m;
  box-shadow: map-get($map: $shadows, $key: 2);
  transition: box-shadow $speed-fast ease-in-out;

  &:hover {
    box-shadow: map-get($map: $shadows, $key: 3);
  }

  &--rtl {
    direction: rtl;
  }

  &--dark {
    color: color-contrast($rt-color-dark);
    background: $rt-color-dark;
  }

  &--default {
    color: color-contrast($rt-color-default);
    background: $rt-color-default;
  }

  &--info {
    color: color-contrast($rt-color-info);
    background: $rt-color-info;
  }

  &--success {
    color: color-contrast($rt-color-success, $color-success-darker);
    background: $rt-color-success;
  }

  &--warning {
    color: color-contrast($rt-color-warning);
    background: $rt-color-warning;
  }

  &--error {
    color: color-contrast($rt-color-error);
    background: $rt-color-error;
  }

  &-body {
    display: flex;
    flex: 1 1 auto;
    margin: auto 0;
  }
}

@media #{$rt-mobile} {
  .#{$rt-namespace}__toast{
    margin-bottom: 0;
  }
}
