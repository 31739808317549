.#{$rt-namespace}__close-button {
  align-self: flex-start;
  padding: 0;
  color: inherit;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  opacity: .7;
  transition: .3s ease;

  &--default {
    color: #000;
    opacity: .3;
  }

  > svg {
    width: 14px;
    height: 16px;
    fill: currentColor;
  }

  &:hover,
  &:focus {
    opacity: 1;
  }
}
