@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";

body {
  padding-top: 70px;
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 10px 28px;
  background: $color-white;
  box-shadow: $shadow-s $shadow-color;
}

.logo {
  height: 100%;
  margin-right: $size-l;
  line-height: 0;
}

.logoImg {
  display: inline-block;
  max-width: 100%;
  height: 100%;
}

.menu {
  display: flex;
  flex-grow: 1;
  padding: 0;
  margin: 0;
}

.item {
  position: relative;
  padding: ($size-s + 2) $size-s;
  font-size: $font-size-s;
  font-weight: $font-weight-semi-bold;
  line-height: 1.25;
  color: $color-main;
  vertical-align: middle;
  border-radius: $border-radius-s;
  transition: background-color .2s linear;

  &:hover {
    background-color: rgba($color-ink-lighter, .3);
  }

  &.active {
    &::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: $size-xxs;
      content: "";
      background: $color-primary;
      border-top-left-radius: $border-radius-l;
      border-top-right-radius: $border-radius-l;
    }
  }
}
