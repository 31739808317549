@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";

// ==============================================================
// Mixins
// ==============================================================

@mixin tooltip-background-color($color) {
  background-color: $color !important;

  &:global(.place-top)::after {
    border-top-color: $color !important;
  }

  &:global(.place-right)::after {
    border-right-color: $color !important;
  }

  &:global(.place-bottom)::after {
    border-bottom-color: $color !important;
  }

  &:global(.place-left)::after {
    border-left-color: $color !important;
  }
}

// ==============================================================
// Styles
// ==============================================================

.tooltip {
  max-width: 205px;
  padding: 12px;
  font-size: $font-size-s;
  line-height: $line-height-text;
  color: $color-white !important;
  letter-spacing: .04em;

  // --------------------------------------------------------------
  // Status
  // --------------------------------------------------------------

  &.--dark {
    @include tooltip-background-color($color-primary-darkest);
  }

  &.--success {
    @include tooltip-background-color($color-success);
  }

  &.--warning {
    color: $color-ink !important;
    @include tooltip-background-color($color-warning);
  }

  &.--error {
    @include tooltip-background-color($color-danger);
  }
}
