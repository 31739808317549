@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";

:global(.sas-dropdown) .item,
:global(.sas-user-menu-dropdown) .item,
.item {
  &:global(.sas-button) {
    justify-content: flex-start;
    height: 50px;
    padding: $size-xxs $size-m;
    border-radius: 0;

    &:first-child,
    &:last-child {
      height: 51px;
    }

    &.colorDefault {
      color: $color-ink;
    }

    &.colorPrimary {
      color: $color-primary;
    }

    &.colorDanger {
      color: $color-danger;
    }

    &:focus {
      box-shadow: none !important;
    }

    &:hover,
    &:focus {
      background-color: $color-ice;
    }
  }
}
