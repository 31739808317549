@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";

.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: $color-ink-lightest;
  border-radius: $border-radius-circle;
}

.text {
  position: relative;
  z-index: 1;
  font-size: $font-size-s;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-text;
  color: $color-ink-light;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: auto;
}

.isLarge {
  min-width: 44px;
  height: 44px;
}

.isMedium {
  min-width: 36px;
  height: 36px;
}

.isSmall {
  min-width: 32px;
  height: 32px;
}

.userIcon {
  width: $size-m;
  color: $color-ink-light;

  .isSmall & {
    width: 18px;
  }
}
