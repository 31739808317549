@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";

// ==============================================================
// Variables
// ==============================================================

// Box padding
$box-padding-l: $size-l;
$box-padding-m: $size-m;
$box-padding-s: $size-s;

// Box radius
$box-radius-l: $size-s;
$box-radius-m: $size-xs;
$box-radius-s: $size-xxs;

// Box shadow
$shadows: ( 1: $shadow-s $shadow-color, 2: $shadow-m $shadow-color, 3: $shadow-l $shadow-color, 4: $shadow-xl $shadow-color );

// ==============================================================
// Styles
// ==============================================================

.box {
  padding: $size-s;
  background: $color-white;
  @include transition($speed-fast);

  &.--outline {
    border: 1px solid $color-ink-lightest;
  }

  &.--rounded {
    border-radius: $box-radius-m;
  }

  @each $key, $shadow in $shadows {
    &.--elevation-#{$key} {
      box-shadow: $shadow;
    }

    &.--elevation-hover-#{$key}:hover {
      box-shadow: $shadow;
    }
  }
}
