@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";

.loader {
  position: relative;
  display: block;
  max-width: 100%;
  height: $size-s;
  overflow: hidden;
  background: $skeleton-color;
  border-radius: $border-radius-xs;

  &::before {
    background: linear-gradient(140deg, transparent 25%, $skeleton-highlight 50%, transparent 75%);
    animation-name: pulse;
    animation-duration: 1600ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    @include pseudo();
    @include cover(absolute);
  }
}

// --------------------------------------------------------------
// Animation
// --------------------------------------------------------------

@keyframes pulse {
  0% {
    transform: translate3d(-80%, 0, 0);
  }

  100% {
    transform: translate3d(120%, 0, 0);
  }
}
