@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";

.user {
  display: inline-flex;
  align-items: center;
  padding: $size-xxs;
}

.avatar {
  margin-right: $size-s;
  border-radius: $border-radius-circle;
}

.info {
  max-width: 140px;
}

.profileName,
.userName {
  margin-bottom: $size-xxs;
}
