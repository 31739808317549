@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";

.button {
  position: relative;
  font-weight: $font-weight-bold;
  line-height: $line-height-reset;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: none;
  appearance: none;
  @include flex-center($inline: true);

  &:focus {
    outline: 0;
  }

  &:disabled {
    @include disabled-style();
  }

  &.--loading {
    cursor: progress;
    opacity: 1;
  }
}

.buttonText {
  .--loading & {
    opacity: 0;
    transform: scale(0);
  }
}

// --------------------------------------------------------------
// Button Sizes
// --------------------------------------------------------------

.--large {
  height: $button-size-l;
  font-size: $font-size-m;
  border-radius: $border-radius-l;
  @include space-inset(0 $button-padding-l);
}

.--medium {
  height: $button-size-m;
  font-size: $font-size-s;
  border-radius: $border-radius-m;
  @include space-inset(0 $button-padding-m);
}

.--small {
  height: $button-size-s;
  font-size: $font-size-xs;
  border-radius: $border-radius-s;
  @include space-inset(0 $button-padding-s);
}

// --------------------------------------------------------------
// Button Variations
// --------------------------------------------------------------

.--primary {
  color: $color-white;
  @include button-style($color-primary);
}

.--secondary {
  color: $color-ink-light;
  border: 1px solid rgba($color-ink, .25);
  @include button-style($color-white);
}

.--tertiary {
  color: $color-ink-light;
  background: transparent;
  box-shadow: none;
  @include space-inset(0 $size-xs);

  &:hover {
    background: $color-ink-lightest;
  }

  &:focus {
    @include focus-style();
  }
}

.--danger {
  color: $color-white;
  @include button-style($color-danger);
}

.--link {
  color: $color-primary;
  background-color: transparent;

  &:hover {
    color: color-contrast($color-ice, $color-primary);
    background-color: $color-ice;
  }
}

// --------------------------------------------------------------
// States
// --------------------------------------------------------------

.--active {
  background-color: $color-primary-lightest;
}

// --------------------------------------------------------------
// Container Size
// --------------------------------------------------------------
.--full-width {
  width: 100%;
}

// --------------------------------------------------------------
// Icons
// --------------------------------------------------------------

.--iconOnly {
  padding: 0;

  &.--large {
    width: $button-size-l;
  }

  &.--medium {
    width: $button-size-m;
  }

  &.--small {
    width: $button-size-s;
  }
}

.icon {
  line-height: 0;
  @include flex-center($inline: true);

  .--large & {
    @include element-icon-size($button-icon-size-l);
  }

  .--medium & {
    @include element-icon-size($button-icon-size-m);

    svg {
      stroke-width: 2.3;
    }
  }

  .--small & {
    @include element-icon-size($button-icon-size-s);

    svg {
      stroke-width: 2.5;
    }
  }

  .--loading & {
    opacity: 0;
    transform: scale(0);
  }
}

.prepend {
  .--large & {
    margin-right: ($button-padding-l / 2);
  }

  .--medium & {
    margin-right: ($button-padding-m / 2);
  }

  .--small & {
    margin-right: ($button-padding-s / 2);
  }
}

.append {
  .--large & {
    margin-left: ($button-padding-l / 2);
  }

  .--medium & {
    margin-left: ($button-padding-m / 2);
  }

  .--small & {
    margin-left: ($button-padding-s / 2);
  }
}
